






























































import Vue, { PropType } from 'vue';
import { DiagramModel } from '@/types/diagram';
import Btn from '@/components/buttons/Btn.vue';
import AppCard from '@/components/card/AppCard.vue';
import DiagramBasicDetails from '@/views/diagram/DiagramBasicDetails.vue';
import SelectDiagramVersion from '@/views/diagram/SelectDiagramVersion.vue';
import DeleteDiagramVersion from '@/views/diagram/DeleteDiagramVersion.vue';
import DiagramVersionForm from '@/views/diagram/DiagramVersionForm.vue';
import { DiagramStatus } from '@/bridge/enums/diagramOptions';

export default Vue.extend({
  name: 'DiagramDetails',
  components: {
    Btn,
    AppCard,
    DiagramBasicDetails,
    DiagramVersionForm,
    DeleteDiagramVersion,
    SelectDiagramVersion,
  },
  props: {
    diagramDetails: {
      type: Object as PropType<DiagramModel>,
      required: true,
    },
  },
  computed: {
    isLocked(): boolean {
      return this.diagramDetails.version.locked;
    },
    isPublished(): boolean {
      if (this.diagramDetails.version && this.diagramDetails.version.status) {
        return this.diagramDetails.version.status === DiagramStatus.PUBLISHED;
      }
      return false;
    },
    canPublish(): boolean {
      if (!this.$hasProjectRole(this.$Roles.CONTRIBUTOR)) return false;

      if (this.diagramDetails.version && this.diagramDetails.version.status) {
        const { status } = this.diagramDetails.version;
        return status !== DiagramStatus.DRAFT && !this.isPublished;
      }
      return false;
    },
    modelPath(): string {
      const { orgId, projectId, diagramId, versionId } = this.$route.params;
      const { type } = this.diagramDetails;
      return `/org/${orgId}/project/${projectId}/model-${type}/${diagramId}/version/${versionId}`;
    },
    reviewPath(): string {
      const { orgId, projectId, diagramId, versionId } = this.$route.params;
      const { type } = this.diagramDetails;
      return `/org/${orgId}/project/${projectId}/review-${type}/${diagramId}/version/${versionId}`;
    },
  },
  methods: {
    async setLatestVersion() {
      await (this.$refs.selectDiagramVersion as any).setLatest();
    },
    async onVersionUpdate() {
      const latestVersion = await (this.$refs.selectDiagramVersion as any).setLatest();
      this.$emit('updateVersionLabel', latestVersion.label);
    },
  },
});
