




















import Vue from 'vue';
import DeleteModal from '@/components/DeleteModal.vue';
import Btn from '@/components/buttons/Btn.vue';

export default Vue.extend({
  name: 'DeleteDiagramVersion',
  components: { Btn, DeleteModal },
  props: {
    diagramVersion: {
      type: String,
      required: true,
    },
    disable: Boolean,
  },
  computed: {
    diagramId(): string {
      return this.$route.params.diagramId;
    },
    projectId(): string {
      return this.$route.params.projectId;
    },
    url(): string {
      return `/project/${this.projectId}/diagram/${this.diagramId}/version/remove`;
    },
  },
});
