

















































import Vue, { PropType, VueConstructor } from 'vue';
import { FormWrapperRequest } from '@/types/form';
import formSubmit from '@/mixins/formSubmit';
import Btn from '@/components/buttons/Btn.vue';
import AppModal from '@/components/AppModal.vue';
import ApiError from '@/components/ApiError.vue';
import FieldLabel from '@/components/fields/FieldLabel.vue';
import { DiagramModel } from '@/types/diagram';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'DiagramVersionForm',
  components: {
    Btn,
    AppModal,
    ApiError,
    FieldLabel,
  },
  mixins: [formSubmit],
  props: {
    isEditForm: Boolean,
    disable: Boolean,
    diagramDetails: {
      type: Object as PropType<DiagramModel>,
      required: true,
    },
  },
  computed: {
    title(): string {
      const action = this.isEditForm ? 'Edit' : 'Create Alternate';
      return `${action} Version`;
    },
    request(): FormWrapperRequest {
      const { id: diagramId, projectId } = this.diagramDetails;
      const { id: versionId } = this.diagramDetails.version;

      const url = `/project/${projectId}/diagram/${diagramId}/version?versionId=${versionId}`;

      if (this.isEditForm) {
        return {
          config: {
            url,
            method: 'put',
            data: this.model,
          },
        };
      }

      return {
        config: {
          url,
          method: 'post',
        },
      };
    },
  },
  data() {
    return {
      model: {
        label: '',
      },
    };
  },
  methods: {
    open() {
      this.model.label = this.diagramDetails.version.label;
      (this.$refs.modal as any).open();
    },
    async onSubmit() {
      this.error = '';
      const resp = await this.submit(this.request, this.$refs.form);
      if (this.hasError) {
        this.loading = false;
        return;
      }

      (this.$refs.modal as any).close();
      this.model.label = '';
      this.$emit('submit', resp);
      this.notify(`B&IT Version ${this.isEditForm ? 'Updated' : 'Created'}`);
      this.loading = false;
    },
  },
});
