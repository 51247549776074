













import Vue, { PropType, VueConstructor } from 'vue';
import { FormWrapperRequest } from '@/types/form';
import formSubmit from '@/mixins/formSubmit';
import Btn from '@/components/buttons/Btn.vue';
import { DiagramStatus, DiagramType } from '@/bridge/enums/diagramOptions';
import { capitalizeFirstWord } from '@/util/strings';
import {
  diagramStatusAction,
  diagramStatusColor,
  nextDiagramStatus,
} from '@/components/diagramStatus/diagramStatusUtils';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'DiagramStatusForm',
  components: {
    Btn,
  },
  mixins: [formSubmit],
  props: {
    diagramStatus: {
      type: String as PropType<DiagramStatus>,
      required: true,
    },
    diagramType: {
      type: String as PropType<DiagramType>,
      required: true,
    },
  },
  computed: {
    btnLabel(): string {
      return diagramStatusAction(this.diagramStatus, this.diagramType);
    },
    btnColor(): string {
      return diagramStatusColor(this.diagramStatus, true);
    },
    nextStatus(): DiagramStatus {
      return nextDiagramStatus(this.diagramStatus);
    },
    request(): FormWrapperRequest {
      const { diagramId, projectId, versionId } = this.$route.params;

      return {
        config: {
          url: `/project/${projectId}/diagram/${diagramId}/version?versionId=${versionId}`,
          method: 'put',
          data: { status: this.nextStatus },
        },
      };
    },
  },
  methods: {
    async onSubmit() {
      await this.submit(this.request);

      if (this.error || this.hasError) {
        this.loading = false;
        return;
      }

      this.$emit('submit', this.nextStatus);
      this.notify(`Status updated to ${capitalizeFirstWord(this.nextStatus)}`);
      this.loading = false;
    },
  },
});
