





































import Vue, { PropType } from 'vue';
import { DiagramModel } from '@/types/diagram';
import DetailsCardRow from '@/components/card/DetailsCardRow.vue';
import DiagramStatusText from '@/components/diagramStatus/DiagramStatusText.vue';

export default Vue.extend({
  name: 'DiagramBasicDetails',
  components: {
    DetailsCardRow,
    DiagramStatusText,
  },
  props: {
    diagramDetails: {
      type: Object as PropType<DiagramModel>,
      required: true,
    },
  },
});
