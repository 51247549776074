
































































import Vue, { PropType } from 'vue';
import { OrgModel } from '@/types/org';
import { ProjectModel } from '@/types/project';
import { DiagramModel, DiagramVersion } from '@/types/diagram';
import { DiagramStatus, DiagramType } from '@/bridge/enums/diagramOptions';
import ApiService from '@/services/api.service';
import Page from '@/layouts/Page.vue';
import AppCard from '@/components/card/AppCard.vue';
import DiagramForm from '@/views/diagram/DiagramForm.vue';
import DeleteDiagram from '@/views/diagram/DeleteDiagram.vue';
import ExportBtn from '@/components/list/ExportBtn.vue';
import DiagramCanvas from '@/views/diagram/DiagramCanvas.vue';
import DiagramLocked from '@/components/diagramStatus/DiagramLocked.vue';
import PageSpinner from '@/components/PageSpinner.vue';
import DiagramZoomSlider from '@/views/diagram/toolbar/ZoomSlider.vue';
import DiagramStatusForm from '@/components/diagramStatus/DiagramStatusForm.vue';
import ShareDiagram from '@/views/diagram/toolbar/ShareDiagram.vue';
import DiagramDetails from '@/views/diagram/DiagramDetails.vue';
import notify from '@/util/notify';
import ExportDiagram from '@/views/diagram/toolbar/ExportDiagram.vue';

export default Vue.extend({
  name: 'ViewDiagram',
  components: {
    ExportBtn,
    DiagramLocked,
    ExportDiagram,
    DiagramDetails,
    DiagramStatusForm,
    DiagramZoomSlider,
    PageSpinner,
    DiagramCanvas,
    DeleteDiagram,
    DiagramForm,
    ShareDiagram,
    Page,
    AppCard,
  },
  props: {
    diagramType: {
      type: String as PropType<DiagramType>,
      default: DiagramType.BIT,
    },
  },
  computed: {
    diagramDetails(): DiagramModel|null {
      return this.$store.getters['diagramModule/details'];
    },
    version(): DiagramVersion|null {
      return this.$store.getters['diagramModule/version'];
    },
    isLocked(): boolean {
      return this.diagramDetails !== null && this.diagramDetails.version.locked;
    },
    isPublished(): boolean {
      if (this.version && this.version.status) {
        return this.version.status === DiagramStatus.PUBLISHED;
      }
      return false;
    },
    org(): OrgModel {
      return this.$store.getters['orgModule/details'];
    },
    project(): ProjectModel {
      return this.$store.getters['projectModule/details'];
    },
    breadcrumbs(): any[] {
      let listPath = 'bits';
      if (this.diagramDetails?.type === DiagramType.DAV) {
        listPath = 'davs';
      }

      return [
        {
          label: this.org.label,
          url: `/org/${this.org.id}/projects`,
        },
        {
          label: this.project.label,
          url: `/org/${this.org.id}/project/${this.project.id}/${listPath}`,
        },
        {
          label: `View ${this.diagramDetails?.label}` || '',
        },
      ];
    },
  },
  data() {
    return {
      diagramLoaded: false,
      diagramDivId: 'viewDiagram',
      error: '',
      loading: false,
    };
  },
  watch: {
    $route() {
      this.loadDiagramDetails();
    },
  },
  methods: {
    async updateDiagramStatus(status: DiagramStatus) {
      await this.$store.dispatch('diagramModule/updateStatus', status);
    },
    async updateVersionLabel(label: string) {
      await this.$store.dispatch('diagramModule/updateVersionLabel', label);
    },
    async onPublish() {
      if (!this.diagramDetails) return;

      this.loading = true;
      try {
        const { projectId, diagramId, versionId } = this.$route.params;

        const metadata = {
          ...this.diagramDetails.metadata,
          [versionId]: {
            publishedAt: new Date().getTime(),
            label: this.diagramDetails.version.label,
          },
        };

        await ApiService.put(`/project/${projectId}/diagram/${diagramId}`, {
          metadata,
          label: this.diagramDetails.label,
          code: this.diagramDetails.code,
          description: this.diagramDetails.description,
        });
        await ApiService.post(`/project/${projectId}/diagram/${diagramId}/version/publish?versionId=${versionId}`);
        notify.info('Version Published');

        await this.loadDiagramDetails();
      } catch {
        notify.danger('Publish error. Please try again later');
      }
      this.loading = false;
    },
    async onSelectVersion(version: { id: string }) {
      if (!this.diagramDetails) return;

      const { orgId, projectId, diagramId, versionId } = this.$route.params;
      const { type } = this.diagramDetails;

      if (versionId === version.id) {
        return;
      }

      await this.$router.push(`/org/${orgId}/project/${projectId}/view-${type}/${diagramId}/version/${version.id}`);
    },
    async loadDiagramDetails() {
      this.loading = true;
      this.$store.dispatch('diagramModule/clear');

      this.$store.dispatch('diagramModule/setDiagramDivId', this.diagramDivId);
      await this.$store.dispatch('diagramModule/loadDetails', {
        ...this.$route.params,
        force: true,
      });
      this.loading = false;
    },
  },
  async created() {
    await this.loadDiagramDetails();
  },
  beforeRouteLeave(to, from, next: () => void) {
    this.$store.dispatch('diagramModule/clear');
    next();
  },
});
