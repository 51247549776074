








































import Vue from 'vue';
import ApiService from '@/services/api.service';
import { DiagramVersion } from '@/types/diagram';
import DiagramStatusText from '@/components/diagramStatus/DiagramStatusText.vue';
import { DiagramStatus } from '@/bridge/enums/diagramOptions';

export default Vue.extend({
  name: 'SelectDiagramVersion',
  components: { DiagramStatusText },
  props: {
    required: Boolean,
    value: {
      type: Object,
      required: false,
    },
    diagramDetails: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      options: [] as DiagramVersion[],
    };
  },
  methods: {
    wasPreviouslyPublished(version: DiagramVersion) {
      if (version.status === DiagramStatus.PUBLISHED) {
        return false;
      }

      const { metadata } = this.diagramDetails;

      if (metadata) {
        return Object.keys(metadata).includes(version.id);
      }

      return false;
    },
    validation(selected: string | string[]) {
      if (this.required && !selected) {
        return 'Version is required';
      }

      return true;
    },
    async loadOptions() {
      const { diagramId, projectId } = this.$route.params;
      const response = await ApiService.get(`/project/${projectId}/diagrams/${diagramId}/versions/info`, {
        perPage: 99,
      });

      if (!response.results || !response.results.length) {
        this.options = [];
        return;
      }

      this.options = response.results;
    },
    async setLatest() {
      await this.loadOptions();

      const [latest] = this.options;

      this.$emit('input', latest);
      return latest;
    },
    async filterFn(val: string, update: any, abort: any) {
      try {
        const { diagramId, projectId } = this.$route.params;
        const response = await ApiService.get(`/project/${projectId}/diagrams/${diagramId}/versions/info`, {
          perPage: 99,
          search: val,
        });

        update(() => {
          if (!response.results || !response.results.length) {
            this.options = [];
            return;
          }

          const needle = val.toLowerCase();
          this.options = response.results
            .filter((v: DiagramVersion) => v.label.toLowerCase().includes(needle))
            .map((v: DiagramVersion) => {
              v.previouslyPublished = this.wasPreviouslyPublished(v);
              return v;
            });
        });
      } catch (e) {
        abort();
      }
    },
  },
  async created() {
    await this.loadOptions();
  },
});
