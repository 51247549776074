
























































import Vue, { PropType, VueConstructor } from 'vue';
import { DiagramType } from '@/bridge/enums/diagramOptions';
import formSubmit from '@/mixins/formSubmit';
import { FormWrapperRequest } from '@/types/form';
import Btn from '@/components/buttons/Btn.vue';
import ApiError from '@/components/ApiError.vue';
import AppModal from '@/components/AppModal.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'DeleteDiagram',
  components: {
    Btn,
    AppModal,
    ApiError,
  },
  mixins: [formSubmit],
  props: {
    diagramType: {
      type: String as PropType<DiagramType>,
      required: false,
    },
    ids: {
      type: Array,
      required: false,
    },
  },
  computed: {
    name(): string {
      let plural = '';
      if (this.ids && this.ids.length !== 1) {
        plural = 's';
      }
      const name = this.diagramType === DiagramType.BIT ? 'B&IT' : 'DAV';
      return `${name}${plural}`;
    },
    diagramId(): string {
      return this.$route.params.diagramId;
    },
    orgId(): string {
      return this.$route.params.orgId;
    },
    projectId(): string {
      return this.$route.params.projectId;
    },
    request(): FormWrapperRequest {
      const url = `/project/${this.projectId}/diagram/remove`;
      const method = 'put';

      if (this.diagramId) {
        return {
          config: {
            url,
            method,
            data: { ids: [this.diagramId] },
          },
        };
      }

      // For APIs that support batch delete
      return {
        config: {
          url,
          method,
          data: { ids: this.ids },
        },
      };
    },
  },
  data() {
    return {
      confirmDelete: false,
    };
  },
  methods: {
    open() {
      (this.$refs.modal as any).open();
    },
    async onSubmit() {
      await this.submit(this.request, this.$refs.form);
      if (this.hasError) {
        return;
      }

      if (this.ids) {
        this.notify(`Deleted ${this.ids.length} ${this.name}`);
        this.$emit('deleted');
      } else {
        this.notify(`${this.name} deleted`);
        await this.$router.replace(`/org/${this.orgId}/project/${this.projectId}/${this.diagramType}s`);
      }

      if (this.$refs.modal) {
        (this.$refs.modal as any).close();
      }
    },
  },
});
